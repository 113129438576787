import {
  createBot,
  getBot,
  getBots,
  updateBot,
  getUserBotHistory,
  getUserBotHistoryMessages,
  deleteBot,
  createContact,
  deleteContact,
  updateConversationToggle,
  openAuthFacebook,
  getNextPage,
} from "@/routes";

export default {
  namespaced: true,
  state: {
    bots: [],
    activeBot: {},
    contacts: {},
  },
  getters: {
    bots: (state) => state.bots,
    activeBot: (state) => state.activeBot,
    contacts: (state) => state.contacts,
  },
  mutations: {
    setBots(state, payload) {
      state.bots = payload;
    },
    setActiveBot(state, payload) {
      state.activeBot = payload;
    },
    setContact(state, payload) {
      state.contacts = payload;
    },
    REMOVE_CONTACT(state, payload) {
      if (payload) {
        if (payload.type == "email") {
          let index = _.findIndex(state.contacts.emails, (email) => {
            return email.id == payload._data.ids;
          });
          if (index >= 0) {
            state.contacts.emails.splice(index, 1);
          }
        } else {
          let index = _.findIndex(state.contacts.mobile_numbers, (number) => {
            return number.id == payload._data.ids;
          });
          if (index >= 0) {
            state.contacts.mobile_numbers.splice(index, 1);
          }
        }
      }
    },
  },
  actions: {
    getBots({ commit, state }, reloadedBot) {
      return getBots().then(
        (res) => {
          commit("setBots", res.data);

          let _activeBot = state.bots.find((i) => i.id == reloadedBot);

          // set active bot based on paramId but if null set first bot
          commit("setActiveBot", reloadedBot ? _activeBot : res.data[0]);

          // set contacts
          let _contacts = {
            mobile_numbers: reloadedBot
              ? _activeBot?.mobile_numbers
              : res.data[0].mobile_numbers,
            emails: reloadedBot ? _activeBot?.emails : res.data[0].emails,
          };
          commit("setContact", _contacts);

          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },

    getBot({ commit }, id) {
      return getBot(id);
    },

    createBot({ commit }, data) {
      return createBot(data);
    },

    updateBot({}, { chatbotId, data }) {
      return updateBot(chatbotId, data);
    },

    deleteBot({}, { botId }) {
      return deleteBot(botId).then((res) => {});
    },

    getUserBotHistory({ commit }, id) {
      return getUserBotHistory(id);
    },
    getUserBotHistoryMessages({ commit }, { id, convoId }) {
      return getUserBotHistoryMessages(id, convoId);
    },

    createContact({}, { botId, data }) {
      return createContact(botId, data);
    },

    deleteContact({}, { type, _data }) {
      return deleteContact(type, _data).then((res) => {});
    },

    updateConversationToggle({}, { data }) {
      return updateConversationToggle(data);
    },

    openAuthFacebook({}) {
      return openAuthFacebook();
    },

    getNextPage({ commit }, url) {
      return getNextPage(url);
    },
  },
};
